<template>
	<div class="login">
		<loginHeader :type='1'></loginHeader>
		<div class="loginCon">
			<div class="container">
				<div class="img">
					<img src="../../assets/img/login/loginback.png" alt="">
				</div>
				<!-- 右边登录 -->
				<div class="loginright">
					<!-- 登录form -->
					<div class="from-code" v-if="isLoginWay!=3">
						<div class="loginone">
							<h2>欢迎登录</h2>
							<!-- 账号登录 -->
							<formLink v-if="isLoginWay == 1" @pwd-login-form="pwdLoginForm"></formLink>
							<!-- 手机快捷登录 -->
							<smsFormLink v-if="isLoginWay == 2" @sms-login-form="smsLoginForm"></smsFormLink>
							
							<div class="other-info" v-if="isLoginWay ==1 ">
								<div class="go-register">
									没有账号？
									<router-link to="/enroll" class="zhuce J-register">立即注册</router-link>
								</div>
								<div class="forget-password">
									<router-link :to="{name:'enroll', query: {isForget: 1}}" class="J-forget">忘记密码？</router-link>
								</div>
							</div>
						</div>
						<div class="line"></div>
						<!-- 二维码 -->
						<div class="qrCode">
							<img :class="isLoginWay ==2? 'mt48':'' " src="../../assets/img/login/zpxzs.jpeg" alt="">
							<p>
								<span>扫码关注&nbsp;厦门国拍</span>
								<span>平台公众号</span>
							</p>
						</div>
					</div>
					<!-- 微信登录 -->
					<div class="wxLogin" v-if="isLoginWay == 3">
						<div class="title">微信登录</div>
						<div class="wrp_code">
							<img class="qrcode lightBorder" src="../../assets/img/login/code.jpeg">
						</div>
						<div class="text">
							<p>请使用微信扫描二维码登录</p>
							<p>“中拍平台”</p>
						</div>
					</div>
					
					<!-- 切换按钮 -->
					<div class="login-outnavboxv">
						<div class="login-nav">
							<div class="login-nav-item" v-for="(item,index) in loginNav" v-show="item.show =='1'?true:false " @click="loginWay(item.active)">
								<img :src="item.img" alt=""> 
								<em>{{item.name}}</em>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	
		
	</div>
</template>

<script>
	import loginHeader from '@/components/login/loginHeader.vue'
	import formLink from '@/components/login/form.vue'
	import smsFormLink from '@/components/login/smsForm.vue'
	import { login,smsLogin } from '@/axios/api.js'
	import { ACC_REG } from '@/utils/reg.js'
	import { mapState,mapMutations } from 'vuex'
	export default {
		data() {			
			return {
				pasdIcon: require('../../assets/img/home/money.png'),
				loginNav: [{
					img: require('../../assets/img/login/passwordlogin.png'),
					name: '账号密码',
					show: '0',
					active: '1'
				},
				{
					img: require('../../assets/img/login/phonelogin.png'),
					name: '手机快捷登录',
					show: '1',
					active: '2'
				}],
				// {
				// 	img: require('../../assets/img/login/wxlogin.png'),
				// 	name: '微信登录',
				// 	show: '1',
				// 	active: '3'
				// },
				isLoginWay: '1',//登录方式 1=账号，2=手机验证码,3=微信登录		
			}
		},
		components: {
			loginHeader,
			formLink,
			smsFormLink
		},		
		computed: {
			...mapState(['login'])
		},
		methods: {
			...mapMutations(['updateLogin']),
			loginWay(active){
				console.log(active)
				this.isLoginWay = active
				this.loginNav.forEach((item)=>{
					item.show = 1
					if(item.active == active){						
						item.show = 0
					}
				})
			},
			// 账号登录
			pwdLoginForm(value){
				console.log(value)
				let that = this
				let data = {
					mobile: value.phone,
					pwd: value.password
				}
				login(data).then(function(res) {
					console.log('登录:', res.data)
					let data = res.data
					if (data.code == 0 || data.code == 1) {
						that.$message({
							message: data.msg,
							type: 'success'
						});
						//更新登录态
						let userInfo = {
							mobile: value.phone,
							name: data.result.name,
							type: data.result.type,				
							isLogin: 1
						}
						that.updateLogin(true)
						sessionStorage.setItem('userInfo',JSON.stringify(userInfo))
						//跳转到首页
						that.$router.push({
							path: '/index', 
						})
					} else {
						that.$message({
							message: data.msg,
							type: 'error'
						})
					}
				})
				.catch(function(err) {
					console.log('err:', err)
				})
			},
			// 手机快捷登录
			smsLoginForm(value){
				console.log(value)
				let that = this
				let data = {
					mobile: value.phone,
					code: value.code
				}
				smsLogin(data).then(function(res) {
					console.log('手机快捷登录:', res.data)
					let data = res.data
					if (data.success) {
						that.$message({
							message: data.msg,
							type: 'success'
						});
						//更新登录态
						let userInfo = {
							mobile: value.phone,
							name: data.result.name,
							type: data.result.type,				
							isLogin: 1
						}
						that.updateLogin(true)
						sessionStorage.setItem('userInfo',JSON.stringify(userInfo))
						//跳转到首页
						that.$router.push({
							path: '/index', 
						})
					} else {
						that.$message({
							message: data.msg,
							type: 'error'
						})
					}
				})
				.catch(function(err) {
					console.log('err:', err)
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.loginCon {
		width: 100%;
		height: 600px;
		background: #f24b53;

		.container {
			height: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.loginright {
			width: 550px;
			// height: 375px;
			margin: 100px 0px;
			padding: 15px 15px 10px;
			background: #fff;

			h2 {
				color: #333;
				font-size: 18px;
			}
		}
		.from-code{
			display: flex;
		}
		.loginone {
			display: inline-block;
			width: 320px;
			margin-top: 22px;

		}

		/deep/.el-form {
			margin: 70px 0 0;

			.el-form-item__content {
				line-height: 38px;
			}

			.el-input__inner {
				height: 38px;
				line-height: 38px;
				border: none;
				border-bottom: solid 1px #dadada;
				border-radius: 0px;
				color: #333;
			}

			.el-input-group__prepend {
				width: 25px;
				background: none;
				border: none;
				border-bottom: solid 1px #dadada;
				border-radius: 0;
				padding: 0;

				img {
					display: block;
					width: 25px;
					height: 25px;
					margin: 0 auto;

					&.name {
						width: 20px;
						height: 20px;
					}
				}
			}

			.el-button {
				background: #D62D26;
				border: 1px solid #DCDFE6;
				color: #fff;
				height: 35px;
				line-height: 35px;
				font-size: 16px;
				width: 100%;
				cursor: pointer;
				border-radius: 3px;
				padding: 0;
			}
		}

		.other-info {
			padding: 12px 0;
			font-size: 12px;
			color: #666;
			overflow: hidden;
			-webkit-user-select: none;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.go-register {
				font-size: 12px;
				color: #666;

				.zhuce,
				.zhuce:hover {
					color: #f7797e;
					margin-left: 10px;
				}
			}
		}

		.line {
			width: 1px;
			height: 196px;
			background: #EBEBEB;
			margin: 80px 0 0 20px;
		}

		.qrCode {
			display: inline-block;
			padding-top: 22px;
			margin-left: 20px;
			vertical-align: top;

			img {
				display: block;
				width: 160px;
				margin: 70px 0 10px;
				// margin: 48px 0 10px;
				&.mt48{
					margin-top: 48px;
				}
			}

			p {
				width: 98px;
				margin: 0 auto;
				text-align: center;
				font-size: 14px;
				font-weight: bold;
				line-height: 20px;
			}
		}
		.login-outnavboxv {
		    width: 100%;
		    border-top: 1px solid #EBEBEB;
			.login-nav {
			    width: 50%;
			    overflow: hidden;
			    height: 32px;
			    margin: 15px auto 0;
				display: flex;
			}
			.login-nav-item{
				margin-left: 10px;
				cursor: pointer;
				display: flex;
				img {
				    display: block;
				    width: 32px;
				    height: 32px;
				}
				em {
					display: block;
				    font-size: 14px;
				    display: inline-block;
				    margin-right: 9px;
				    line-height: 32px;
				    margin-left: 4px;
				}
			}
		}
		.wxLogin{
			margin: 25px 0 0 0;
			color: rgb(55, 55, 55);
			text-align: center;
			.title {
			    text-align: center;
			    font-size: 20px;
			}
			.qrcode {
			    width: 180px;
				margin-top: 15px;
				border: 1px solid #E2E2E2;
			}
			.text{	
				width: 280px;
				margin: 0 auto;
				padding: 14px 0px;
				p {
				    font-size: 13px;
					line-height: 20px;
				}
			}
		}
	}
	
</style>
