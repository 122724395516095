<template>
	<div class="form">
		<!-- 1=账号 -->
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px"
			class="demo-ruleForm">
			<el-form-item label="" prop="phone">
				<!-- 用户名 -->
				<el-input v-model="ruleForm.phone" placeholder="请输入手机号" clearable>
					<template slot="prepend">
						<img class="name" src="../../assets/img/login/name.png" alt="">
					</template>
				</el-input>
			</el-form-item>
			<!-- 密码 -->
			<el-form-item label="" prop="password">
				<el-input v-model="ruleForm.password" type="password" placeholder="请输入密码" clearable>
					<template slot="prepend">
						<img src="../../assets/img/login/password.png" alt="">
					</template>
				</el-input>
			</el-form-item>
			<!-- 登录 -->
			<el-form-item>
				<el-button type="primary" @click="pwdLoginForm('ruleForm')">登录</el-button>
			</el-form-item>		
		</el-form>
		
	</div>
</template>

<script>	
	import { ACC_REG } from '@/utils/reg.js'
	export default {
		// props: ['isLoginWay'],
		data() {
			// 手机号
			var validatePhone = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入手机号'));
				} else if (!this.checkAccount()) {
					callback(new Error('请输入正确的手机号码'));
				} else {
					callback();
				}
			};
			return {
				getSmsStatus: false,
				smsNum: 60,
				smsTxt: '获取验证码',
				ruleForm: {
					phone: '',
					password: ''
				},
				rules: {
					phone: [{
						validator: validatePhone,
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}]
				},
			}
		},
		methods: {			
			//验证账号
			checkAccount() {
				let phone = this.ruleForm.phone
				if (ACC_REG.test(phone) && phone.toString().length == 11) {
					return true
				} else {
					return false
				}
			},
			// 账号登录
			pwdLoginForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$emit('pwd-login-form',this.ruleForm)
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			//获取验证码
			getSms() {
				if (this.getSmsStatus) {
					return
				}
				if (!this.checkAccount()) {
					this.$message({
						message: '请输入正确的手机号码',
						type: 'error'
					})
					return
				}
			
				let that = this
				let obj = {
					mobile: this.ruleForm.phone
				}
				// sendSms(obj).then(function(res) {
				// 	console.log('sendSMS:', res.data)
				// 	let data = res.data
				// 	if (data.code == 0) {
				// 		that.getSmsStatus = true
				// 		that.timeObj = setInterval(function() {
				// 			if (that.smsNum == 1) {
				// 				clearInterval(that.timeObj)
				// 				that.smsNum = 60
				// 				that.getSmsStatus = false
				// 				that.smsTxt = '获取验证码'
				// 			} else {
				// 				that.smsNum--
				// 				that.smsTxt = `${that.smsNum}s`
				// 			}
				// 		}, 1000)
				// 	} else {
				// 		that.$message({
				// 			message: data.msg,
				// 			type: 'error'
				// 		})
				// 	}
				// })
				// .catch(function(err) {
				// 	console.log('sendSMS err:', err)
				// })
			},
		}
	}
</script>

<style lang="less" scoped>
	/deep/.el-form {
		margin: 70px 0 0;
		.el-form-item{
			position: relative;
		}
		.el-form-item__content {
			line-height: 38px;
		}
	
		.el-input__inner {
			height: 38px;
			line-height: 38px;
			border: none;
			border-bottom: solid 1px #dadada;
			border-radius: 0px;
			color: #333;
		}
	
		.el-input-group__prepend {
			width: 25px;
			background: none;
			border: none;
			border-bottom: solid 1px #dadada;
			border-radius: 0;
			padding: 0;
	
			img {
				display: block;
				width: 25px;
				height: 25px;
				margin: 0 auto;
	
				&.name {
					width: 20px;
					height: 20px;
				}
			}
		}
		.code-input{
			.el-input__inner{
				padding-right: 94px;
				
			}
		}
		.obtain-code {
		    color: #c93e45;
		    cursor: pointer;
			position: absolute;
			top: 0;
			right: 0;
			&::before{
				width: 1px;
				height: 18px;
				background: #EBEBEB;
				position: absolute;
				top: 50%;
				right: 94px;
				transform: translate(0,-50%);
				content: "";
			}
		}
	
		.el-button {
			background: #D62D26;
			border: 1px solid #DCDFE6;
			color: #fff;
			height: 35px;
			line-height: 35px;
			font-size: 16px;
			width: 100%;
			cursor: pointer;
			border-radius: 3px;
			padding: 0;
		}
	}
	
</style>
